<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter"
      parallax-active="false"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-80 md-small-size-100 mx-auto text-center"
          >
            <md-card class="md-card md-card-signup">
              <md-card-content>
                <h2 class="card-title text-center">
                  {{item.label}}
                </h2>
                <div class="md-layout">
                  <div
                    class="md-layout-item md-size-100 md-small-size-100 ml-auto"
                  >
                    <youtube :video-id="item.link" ref="youtube" @playing="playing" :fitParent="true" @ended="ended" style='height:400px;'></youtube>
                  </div>
                </div>
                <p class='description mt-2'> {{item.descripcion}}</p>
                <md-button class='md-just-icon md-primary md-round pull-left' @click="goBack">
                  <md-icon>arrow_back</md-icon>
                </md-button>
                <md-button class='md-primary md-round pull-right' @click="abrirCuestionario" v-if="item.type === 'cuestionario'">
                  <md-icon>mode_edit</md-icon>
                  <span style='margin-left:5px;'>Cuestionario</span>
                </md-button>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </parallax>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { mapGetters } from 'vuex'
import youtube from 'vue-youtube'
import Vue from 'vue'
Vue.use(youtube)
export default {
  mixins: [Mixins.HeaderImage],
  bodyClass: "signup-page",
  props: {
    videoId: { type: String, default: '' }
  },
  created () {
    this.getData()
  },
  data() {
    return {
      reproduced: false,
      // image: require("@/assets/img/bg7.jpg"),
      image: require("@/assets/img/blueboard.jpg"),
      item: {}
    }
  },
  computed: {
    ...mapGetters(['badge','userName','config']),
  },
  methods: {
    playing () {
      if (!this.reproduced) {
        let obj = {
            badge: this.badge,
            userName: this.userName,
            video: this.item.label
        }
        this.axios.post('/videoDifusion/registrar', obj, this.config).then(res => {
          console.log('Registrado!')
          this.reproduced = true
        }).catch(err => {
          this.$store.dispatch('handlingServerErrors', err)
        })
      }
    },
    ended () {
      return false
    },
    getData () {
      if (this.video !== '') {
        this.axios.get('/videoDifusion/getVideo/' + this.videoId, this.config).then(res => {
        this.item = res.data
      }).catch(err => {
          this.$store.dispatch('handlingServerErrors', err)
        })
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    abrirCuestionario () {
      if (this.item.cuestionario !== '') {
        this.$router.push({ name: 'cuestionario', params: { cuestionarioId: this.item.cuestionario } })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
