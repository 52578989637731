var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('parallax',{staticClass:"page-header header-filter",style:(_vm.headerStyle),attrs:{"parallax-active":"false"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-80 md-small-size-100 mx-auto text-center"},[_c('md-card',{staticClass:"md-card md-card-signup"},[_c('md-card-content',[_c('h2',{key:_vm.key,staticClass:"card-title text-center"},[_vm._v(" "+_vm._s(_vm.cuestionario.nombre)+" ")]),(
                  !_vm.iniciado &&
                  Object.keys(_vm.cuestionario).length === 0 &&
                  !_vm.mostrarResultado
                )?[_c('center',[_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate","md-diameter":120,"md-stroke":10}})],1)]:_vm._e(),(
                  !_vm.iniciado &&
                  Object.keys(_vm.cuestionario).length > 0 &&
                  !_vm.mostrarResultado
                )?[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100 ml-auto"},[_c('img',{attrs:{"src":_vm.encuesta.encuestaId === '5f7f4624e1e7e53ca8c4c857'
                          ? _vm.eventosTraumaticos
                          : _vm.riesgoPsicosocial,"alt":""}})])]),_c('md-button',{staticClass:"md-just-icon md-primary md-round pull-left",on:{"click":_vm.goBack}},[_c('md-icon',[_vm._v("arrow_back")])],1),_c('md-button',{staticClass:"md-primary md-round pull-right",on:{"click":_vm.iniciarCuestionario}},[_c('md-icon',[_vm._v("border_color")]),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("Iniciar")])],1)]:_vm._e(),(_vm.iniciado && !_vm.mostrarResultado)?[(_vm.encuesta.encuestaId === '5f7f4624e1e7e53ca8c4c857')?_c('capturaEventosSeveros',{attrs:{"periodoId":_vm.encuesta.periodo,"encuestaId":_vm.encuesta.encuestaId}}):_vm._e(),(_vm.encuesta.encuestaId === '5f7f6d9ae1e7e53ca8c4c858')?_c('capturaEntornoOrganizacional',{attrs:{"periodoId":_vm.encuesta.periodo,"encuestaId":_vm.encuesta.encuestaId}}):_vm._e()]:_vm._e()],2)],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }