<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter"
      parallax-active="false"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-80 md-small-size-100 mx-auto text-center"
          >
            <md-card class="md-card md-card-signup">
              <md-card-content>
                <h2 class="card-title text-center">
                  {{cuestionario.nombre}}
                </h2>
                <template v-if="!iniciado && Object.keys(cuestionario).length === 0 && !mostrarResultado">
                  <center>
                    <md-progress-spinner md-mode="indeterminate" :md-diameter="120" :md-stroke="10"></md-progress-spinner>
                  </center>
                </template>
                <template v-if="!iniciado && Object.keys(cuestionario).length > 0 && !mostrarResultado">
                  <div class="md-layout">
                    <div
                      class="md-layout-item md-size-100 md-small-size-100 ml-auto"
                    >
                      <p>Este cuestionario esta compuesto de {{cuestionario.preguntas.length}} preguntas y requiere de un mínimo de calificacion de un {{cuestionario.minimumScore}} %.</p>
                      <p>Solo debes seleccionar la opción que consideras correcta para proceder a la siguiente pregunta.</p>
                      <p>Presiona el botón de iniciar para proceder a la primer pregunta.</p>
                    </div>
                  </div>
                  <md-button class='md-just-icon md-primary md-round pull-left' @click="goBack">
                    <md-icon>arrow_back</md-icon>
                  </md-button>
                  <md-button class='md-primary md-round pull-right' @click="iniciarCuestionario">
                    <md-icon>border_color</md-icon>
                    <span style='margin-left:5px;'>Iniciar</span>
                  </md-button>
                </template>
                <template v-if="iniciado && !mostrarResultado">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100 ml-auto">
                      <full-bg-card :card-image="require('@/assets/img/questions.png')">
                        <template slot="cardContent">
                          <h3 class='card-title' style='margin-top:30px;'>{{cuestionario.preguntas[questionIndex].pregunta}}</h3>
                          <div v-for="(item, index) in cuestionario.preguntas[questionIndex].opciones" :key="index" class='options' :class="{ 'active': cuestionario.preguntas[questionIndex].respuestaUsuario === item.opt}" @click="grabarRespuesta(item.opt)">
                            <p style="text-align:left; padding: 10px 25px 10px 25px;">{{item.label}}</p>
                          </div>
                        </template>
                      </full-bg-card>
                      <p class='description'>Pregunta {{(questionIndex + 1)}} de {{cuestionario.preguntas.length}}</p>
                    </div>
                  </div>
                  <md-button class='md-just-icon md-primary md-round pull-left' @click="prevQuestion" :disabled="questionIndex === 0">
                    <md-icon>arrow_back</md-icon>
                  </md-button>
                  <md-button class='md-just-icon md-primary md-round pull-right' @click="nextQuestion" v-if="questionIndex !== (cuestionario.preguntas.length - 1)">
                    <md-icon>arrow_forward</md-icon>
                  </md-button>
                  <md-button class='md-primary md-round pull-right' @click="finalizarCuestionario" v-if="questionIndex === (cuestionario.preguntas.length - 1)">
                    <md-icon>check</md-icon>
                    <span style='margin-left: 5px;'>Finalizar</span>
                  </md-button>
                </template>
                <template v-if="mostrarResultado">
                  <div class="md-layout">
                    <div class="md-layout-item md-size-100 md-small-size-100 ml-auto">
                      <h5>Gracias por completar el cuestionario!</h5>
                      <h5>Has concluido el cuestionario con {{correctas}} preguntas correctas de un total de {{cuestionario.preguntas.length}}.</h5>
                      <h5>Se necesita un mínimo del {{cuestionario.minimumScore}}% para acreditar, y tu calificación es del {{objToSend.calificacion}}% por tanto:</h5>
                      <template v-if="objToSend.calificacion >= cuestionario.minimumScore">
                        <h1 class="text-success">ACREDITASTE!</h1>
                        <h5>Puedes volver a ver la capacitación cuantas veces gustes en caso de que lo necesites!</h5>
                        <md-button class='md-primary md-round' @click="goToTrainings">
                          <md-icon>school</md-icon>
                          Tomar otro curso
                        </md-button>
                      </template>
                      <template v-if="objToSend.calificacion < cuestionario.minimumScore">
                        <h1 class="text-danger">NO ACREDITASTE!</h1>
                        <h5>Puedes volver a tomar la capacitacion y presentar de nuevo el cuestionario!!</h5>
                        <md-button class='md-primary md-round' @click="goBack">
                          <md-icon>arrow_back</md-icon>
                          Regresar al Video
                        </md-button>
                      </template>
                    </div>
                  </div>
                </template>
              </md-card-content>
            </md-card>
          </div>
        </div>
      </div>
    </parallax>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { FullBgCard } from '@/components'
import { mapGetters } from 'vuex'
import { Message } from 'element-ui'
export default {
  mixins: [Mixins.HeaderImage],
  bodyClass: "signup-page",
  components: {
    FullBgCard
  },
  props: {
    cuestionarioId: { type: String, default: '' }
  },
  created () {
    this.getData()
  },
  data() {
    return {
      iniciado: false,
      mostrarResultado: false,
      questionIndex: 0,
      image: require("@/assets/img/blueboard.jpg"),
      cuestionario: {},
      correctas: 0,
      objToSend: {
        preguntas: [],
        calificacion: 0
      }
    }
  },
  computed: {
    ...mapGetters(['badge','userName','config']),
  },
  methods: {
    getData () {
      if (this.video !== '') {
        this.axios.get('/cuestionarios/getCuestionario/' + this.cuestionarioId, this.config).then(res => {
            this.cuestionario = res.data
        }).catch(err => {
            this.$store.dispatch('handlingServerErrors', err)
        })
      }
    },
    goBack () {
      this.$router.go(-1)
    },
    iniciarCuestionario () {
      this.iniciado = true
    },
    prevQuestion () {
      this.questionIndex--
    },
    nextQuestion () {
      this.questionIndex++
    },
    grabarRespuesta (respuesta) {
      var pregunta = this.cuestionario.preguntas[this.questionIndex]
      pregunta.respuestaUsuario = respuesta
      if (pregunta.respuestaUsuario === pregunta.respuesta) {
        pregunta.correcta = true
      } else {
        pregunta.correcta = false
      }
      if (this.questionIndex !== (this.cuestionario.preguntas.length - 1)) {
        this.questionIndex++
      }
    },
    finalizarCuestionario () {
      if (this.cuestionarioCalculationAndValidators()) {
        this.axios.post('/cuestionarios/registrar', this.objToSend, this.config).then(res => {
          this.mostrarResultado = true
        }).catch(err => {
          this.$store.dispatch('handlingServerErrors', err)
        })
      }
    },
    cuestionarioCalculationAndValidators () {
      var noRespuesta = 0
      var correctas = 0
      this.cuestionario.preguntas.forEach(pregunta => {
        if (pregunta.correcta === true) {
          correctas++
        }
        if (pregunta.respuestaUsuario === '') {
          noRespuesta++
        } else {
          this.objToSend.preguntas.push({
            pregunta: pregunta.pregunta,
            respuesta: pregunta.opciones.find(el => el.opt === pregunta.respuestaUsuario).label,
            correcta: pregunta.correcta
          })
        }
      })
      this.correctas = correctas
      this.objToSend.calificacion = (correctas * 100) / this.cuestionario.preguntas.length
      this.objToSend.calificacion = this.objToSend.calificacion.toFixed(0)
      this.objToSend.badge = this.badge
      this.objToSend.userName = this.userName
      this.objToSend.activo = true
      this.objToSend.cuestionario = this.cuestionario.nombre
      if (noRespuesta > 0) {
        Message({
          message: 'Falta por contestar ' + noRespuesta + ' preguntas.',
          center: true,
          type: 'error'
        })
        return false
      } else {
        return true
      }
    },
    goToTrainings () {
      this.$router.push({ name: 'entrenamientosGenerales'})
    }
  }
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
.options {
  background-color:white;
  // padding: 10px 25px 10px 25px;
  border-radius: 6px;
  margin-bottom: 10px;
  cursor:pointer;
  :hover {
    background-color: #448aff !important;
    border-radius: 6px !important;
    color: white !important;
  }
}
.active {
  background-color:#448aff !important;
  color: white !important;
}
</style>
