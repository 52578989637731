<template>
  <div class="md-layout">
    <template
      v-if="
        encuesta.seccion &&
        pregunta1 === false &&
        pregunta2 === false &&
        terminada === false
      "
    >
      <!-- Score: {{score}} -->
      <h5 style="color: blue">{{ encuesta.seccion[numSeccion].encabezado }}</h5>
      <md-list>
        <md-list-item
          v-for="pregunta in encuesta.seccion[numSeccion].preguntas"
          :key="pregunta.id"
        >
          <div class="md-layout">
            <div class="md-layout-item text-left" sm>
              {{ pregunta.id }}) {{ pregunta.pregunta }}
            </div>
            <div class="md-layout-item">
              <div class="my-2">
                <md-button
                  size="sm"
                  style="font-size: 65%; border-right: 1px solid white"
                  :class="{ 'md-primary': pregunta.respuesta === 1 }"
                  @click="updatingValue(pregunta, 1)"
                  >Siempre</md-button
                >
                <md-button
                  size="sm"
                  style="font-size: 65%; border-left: 1px solid white"
                  :class="{ 'md-primary': pregunta.respuesta === 2 }"
                  @click="updatingValue(pregunta, 2)"
                  >Casi Siempre</md-button
                >
                <md-button
                  size="sm"
                  style="font-size: 65%; border-left: 1px solid white"
                  :class="{ 'md-primary': pregunta.respuesta === 3 }"
                  @click="updatingValue(pregunta, 3)"
                  >Algunas Veces</md-button
                >
                <md-button
                  size="sm"
                  style="font-size: 65%; border-left: 1px solid white"
                  :class="{ 'md-primary': pregunta.respuesta === 4 }"
                  @click="updatingValue(pregunta, 4)"
                  >Casi Nunca</md-button
                >
                <md-button
                  size="sm"
                  style="font-size: 65%; border-left: 1px solid white"
                  :class="{ 'md-primary': pregunta.respuesta === 5 }"
                  @click="updatingValue(pregunta, 5)"
                  >Nunca</md-button
                >
              </div>
            </div>
          </div>
        </md-list-item>
      </md-list>
      <div>
        <md-button
          class="my-3 md-warning"
          @click="prevSection"
          v-if="numSeccion !== 0"
          >Anterior</md-button
        >
        <md-button
          class="my-3 md-success"
          @click="nextSection"
          v-if="numSeccion < 13"
          >Siguiente</md-button
        >
        <md-button
          class="my-3 md-success"
          @click="terminarEncuesta"
          v-if="numSeccion === 13"
          >Finalizar Encuesta</md-button
        >
      </div>
    </template>
    <template v-if="pregunta1 === true">
      <div>
        <h5 style="color: blue">
          La pregunta siguiente está relacionada con la atencion a clientes y
          usuarios:
        </h5>
        <md-list>
          <md-list-item>
            <div class="md-layout">
              <div class="md-layout-item text-left" sm>
                En mi trabajo debo brindar servicio a clientes o usuarios:
              </div>
              <div class="md-layout-item">
                <div class="my-2">
                  <md-button
                    style="border-right: 1px solid white"
                    @click="responderPregunta1(true)"
                    >SI</md-button
                  >
                  <md-button
                    style="border-left: 1px solid white"
                    @click="responderPregunta1(false)"
                    >NO</md-button
                  >
                </div>
              </div>
            </div>
          </md-list-item>
        </md-list>
      </div>
    </template>
    <template v-if="pregunta2 === true">
      <div>
        <!-- <h5 style="color:blue;">La pregunta siguiente está relacionada con la atencion a clientes y usuarios:</h5> -->
        <md-list>
          <md-list-item>
            <div class="md-layout">
              <div class="md-layout-item text-left" sm>
                Soy jefe de otros trabajadores
              </div>
              <div class="md-layout-item">
                <div class="my-2">
                  <md-button
                    style="border-right: 1px solid white"
                    @click="responderPregunta2(true)"
                    >SI</md-button
                  >
                  <md-button
                    style="border-left: 1px solid white"
                    @click="responderPregunta2(false)"
                    >NO</md-button
                  >
                </div>
              </div>
            </div>
          </md-list-item>
        </md-list>
      </div>
    </template>
    <template v-if="terminada === true">
      <div>
        <h1>Gracias por contestar la encuesta</h1>
        <p>
          Recibira un correo electronico como comprobante a su direccion de
          correo.
        </p>
        <p>Espere unos segundos en lo que registramos sus respuestas.</p>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CapturaEntornoOrganizacional",
  props: {
    // periodoId: { type: String, default: '5f7c8b75ded2e4297c26ab14' },
    // encuestaId: { type: String, default: '5f7f6d9ae1e7e53ca8c4c858' }
    periodoId: { type: String, default: null },
    encuestaId: { type: String, default: null },
  },
  data() {
    return {
      encuesta: "",
      numSeccion: 0,
      score: 0,
      resultados: {},
      pregunta1: false,
      pregunta2: false,
      terminada: false,
    };
  },
  created() {
    if (this.periodoId === null || this.encuestaId === null) {
      this.$router.push("/surveys");
    }
    this.getEncuesta();
  },
  methods: {
    getEncuesta() {
      this.axios.get("/nom035/getEncuesta/" + this.encuestaId).then((res) => {
        this.encuesta = res.data[0];
        this.encuesta.seccion.map((i) => {
          i.preguntas.map((e) => {
            e.respuesta = null;
            e.valor = 0;
          });
        });
      });
    },
    updatingValue(item, value) {
      item.respuesta = value;
      switch (item.respuesta) {
        case 1:
          if (item.ponderacion === "directo") {
            item.valor = 0;
          } else {
            item.valor = 4;
          }
          break;
        case 2:
          if (item.ponderacion === "directo") {
            item.valor = 1;
          } else {
            item.valor = 3;
          }
          break;
        case 3:
          if (item.ponderacion === "directo") {
            item.valor = 2;
          } else {
            item.valor = 2;
          }
          break;
        case 4:
          if (item.ponderacion === "directo") {
            item.valor = 3;
          } else {
            item.valor = 1;
          }
          break;
        case 5:
          if (item.ponderacion === "directo") {
            item.valor = 4;
          } else {
            item.valor = 0;
          }
          break;
      }
      this.$forceUpdate();
      let contador = 0;
      this.encuesta.seccion.forEach((linea) => {
        linea.preguntas.forEach((el) => {
          contador = contador + el.valor;
        });
      });
      this.score = contador;
    },
    nextSection() {
      let error = false;
      this.encuesta.seccion[this.numSeccion].preguntas.forEach((pregunta) => {
        if (pregunta.respuesta === null) {
          error = true;
          return;
        }
      });
      if (error) {
        this.$alertify.alert(
          "RBC de MEXICO",
          "Es necesario contestar todas las preguntas."
        );
        return;
      }
      // No errores..
      this.numSeccion++;
      if (this.numSeccion === 12) {
        this.pregunta1 = true;
      }
      if (this.numSeccion === 13) {
        this.pregunta2 = true;
      }
    },
    prevSection() {
      this.numSeccion--;
      if (this.numSeccion === 12) {
        this.pregunta1 = true;
      }
      if (this.numSeccion === 13) {
        this.pregunta2 = true;
      }
    },
    responderPregunta1(valor) {
      if (valor === true) {
        this.pregunta1 = false;
      } else {
        this.encuesta.seccion[12].preguntas[0].valor = 0;
        this.encuesta.seccion[12].preguntas[1].valor = 0;
        this.encuesta.seccion[12].preguntas[2].valor = 0;
        this.encuesta.seccion[12].preguntas[3].valor = 0;
        this.encuesta.seccion[12].preguntas[0].respuesta = null;
        this.encuesta.seccion[12].preguntas[1].respuesta = null;
        this.encuesta.seccion[12].preguntas[2].respuesta = null;
        this.encuesta.seccion[12].preguntas[3].respuesta = null;
        this.pregunta1 = false;
        this.numSeccion++;
        this.pregunta2 = true;
      }
    },
    responderPregunta2(valor) {
      if (valor === true) {
        this.pregunta2 = false;
      } else {
        this.encuesta.seccion[13].preguntas[0].valor = 0;
        this.encuesta.seccion[13].preguntas[1].valor = 0;
        this.encuesta.seccion[13].preguntas[2].valor = 0;
        this.encuesta.seccion[13].preguntas[3].valor = 0;
        this.encuesta.seccion[13].preguntas[0].respuesta = null;
        this.encuesta.seccion[13].preguntas[1].respuesta = null;
        this.encuesta.seccion[13].preguntas[2].respuesta = null;
        this.encuesta.seccion[13].preguntas[3].respuesta = null;
        this.pregunta2 = false;
        this.terminarEncuesta();
      }
    },
    terminarEncuesta() {
      this.terminada = true;
      let preguntas = [];
      // borrar preguntas para ahorrar espacio
      let seccion = this.encuesta.seccion;
      seccion.forEach((section) => {
        delete section.encabezado;
        section.preguntas.forEach((row) => {
          preguntas.push({ id: row.id, valor: row.valor }); // juntar todas las preguntas en un arreglo
          delete row.pregunta;
          delete row.ponderacion;
        });
      });
      // Obtener todos los resultados posibles
      this.resultados = {
        score: this.score,
        categorias: [
          {
            nombre: "Ambiente de Trabajo",
            valor: 0,
            riesgo: "",
            dominios: [
              {
                nombre: "Condiciones en el ambiente de trabajo",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Condiciones peligrosas e inseguras",
                    valor: 0,
                    items: [1, 3],
                    valores: [],
                  },
                  {
                    nombre: "Condiciones deficientes e insalubres",
                    valor: 0,
                    items: [2, 4],
                    valores: [],
                  },
                  {
                    nombre: "Trabajos Peligrosos",
                    valor: 0,
                    items: [5],
                    valores: [],
                  },
                ],
              },
            ],
          },
          {
            nombre: "Factores Propios de la actividad",
            valor: 0,
            riesgo: "",
            dominios: [
              {
                nombre: "Carga de Trabajo",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Cargas cuantitativas",
                    valor: 0,
                    items: [6, 12],
                    valores: [],
                  },
                  {
                    nombre: "Ritmos de trabajo acelerado",
                    valor: 0,
                    items: [7, 8],
                    valores: [],
                  },
                  {
                    nombre: "Carga mental",
                    valor: 0,
                    items: [9, 10, 11],
                    valores: [],
                  },
                  {
                    nombre: "Cargas psicologicas emocionales",
                    valor: 0,
                    items: [65, 66, 67, 68],
                    valores: [],
                  },
                  {
                    nombre: "Cargas de alta responsabilidad",
                    valor: 0,
                    items: [13, 14],
                    valores: [],
                  },
                  {
                    nombre: "Cargas contradictorias o inconsistentes",
                    valor: 0,
                    items: [15, 16],
                    valores: [],
                  },
                ],
              },
              {
                nombre: "Falta del Control sobre el trabajo",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Falta de control y autonomia sobre el trabajo",
                    valor: 0,
                    items: [25, 26, 27, 28],
                    valores: [],
                  },
                  {
                    nombre: "Limitada o nula posibilidad de desarrollo",
                    valor: 0,
                    items: [23, 24],
                    valores: [],
                  },
                  {
                    nombre: "Insuficiente participacion y manejo del cambio",
                    valor: 0,
                    items: [29, 30],
                    valores: [],
                  },
                  {
                    nombre: "Limitada o inexistente capacitacion",
                    valor: 0,
                    items: [35, 36],
                    valores: [],
                  },
                ],
              },
            ],
          },
          {
            nombre: "Organizacion del tiempo de trabajo",
            valor: 0,
            riesgo: "",
            dominios: [
              {
                nombre: "Jornada de trabajo",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Jornadas de trabajo extensas",
                    valor: 0,
                    items: [17, 18],
                    valores: [],
                  },
                ],
              },
              {
                nombre: "Interferencia en la relacion trabajo-familia",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Influencia del trabajo fuera del centro laboral",
                    valor: 0,
                    items: [19, 20],
                    valores: [],
                  },
                  {
                    nombre: "Influencia de la responsabilidades familiares",
                    valor: 0,
                    items: [21, 22],
                    valores: [],
                  },
                ],
              },
            ],
          },
          {
            nombre: "Liderazgo y relaciones en el trabajo",
            valor: 0,
            riesgo: "",
            dominios: [
              {
                nombre: "Liderazgo",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Escasa claridad de funciones",
                    valor: 0,
                    items: [31, 32, 33, 34],
                    valores: [],
                  },
                  {
                    nombre: "Caracteristicas del liderazgo",
                    valor: 0,
                    items: [37, 38, 39, 40, 41],
                    valores: [],
                  },
                ],
              },
              {
                nombre: "Relaciones en el trabajo",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Relaciones sociales en el trabajo",
                    valor: 0,
                    items: [42, 43, 44, 45, 46],
                    valores: [],
                  },
                  {
                    nombre:
                      "Deficiente relacion con los colaboradores que supervisa",
                    valor: 0,
                    items: [69, 70, 71, 72],
                    valores: [],
                  },
                ],
              },
              {
                nombre: "Violencia",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Violencia laboral",
                    valor: 0,
                    items: [57, 58, 59, 60, 61, 62, 63, 64],
                    valores: [],
                  },
                ],
              },
            ],
          },
          {
            nombre: "Entorno Organizacional",
            valor: 0,
            riesgo: "",
            dominios: [
              {
                nombre: "Reconocimiento del desempeño",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Escasa o nula retroalimentacion del desempeño",
                    valor: 0,
                    items: [47, 48],
                    valores: [],
                  },
                  {
                    nombre: "Escaso o nulo reconocimiento y compensacion",
                    valor: 0,
                    items: [49, 50, 51, 52],
                    valores: [],
                  },
                ],
              },
              {
                nombre: "Insuficiente sentido de pertenencia e inestabilidad",
                valor: 0,
                riesgo: "",
                dimensiones: [
                  {
                    nombre: "Limitado sentido de pertenencia",
                    valor: 0,
                    items: [55, 56],
                    valores: [],
                  },
                  {
                    nombre: "Inestabilidad laboral",
                    valor: 0,
                    items: [53, 54],
                    valores: [],
                  },
                ],
              },
            ],
          },
        ],
      };
      // llenar valores en resultados
      this.resultados.categorias.forEach((categoria) => {
        categoria.dominios.forEach((dominio) => {
          dominio.dimensiones.forEach((dimension) => {
            dimension.items.forEach((item) => {
              let v = preguntas.find((el) => el.id === item).valor;
              dimension.valores.push(v);
              dimension.valor = dimension.valor + v;
              dominio.valor = dominio.valor + v;
              categoria.valor = categoria.valor + v;
            });
          });
        });
      });
      // calificar resultados
      switch (true) {
        case this.resultados.score < 50:
          this.resultados.riesgo = "Nulo o despreciable";
          break;
        case this.resultados.score < 75:
          this.resultados.riesgo = "Bajo";
          break;
        case this.resultados.score < 99:
          this.resultados.riesgo = "Medio";
          break;
        case this.resultados.score < 140:
          this.resultados.riesgo = "Alto";
          break;
        case this.resultados.score > 139:
          this.resultados.riesgo = "Muy Alto";
          break;
      }
      this.resultados.categorias.forEach((categoria) => {
        switch (categoria.nombre) {
          case "Ambiente de Trabajo": {
            switch (true) {
              case categoria.valor < 5:
                categoria.riesgo = "Nulo o despreciable";
                break;
              case categoria.valor < 9:
                categoria.riesgo = "Bajo";
                break;
              case categoria.valor < 11:
                categoria.riesgo = "Medio";
                break;
              case categoria.valor < 14:
                categoria.riesgo = "Alto";
                break;
              case categoria.valor > 13:
                categoria.riesgo = "Muy Alto";
                break;
            }
            break;
          }
          case "Factores Propios de la actividad": {
            switch (true) {
              case categoria.valor < 15:
                categoria.riesgo = "Nulo o despreciable";
                break;
              case categoria.valor < 30:
                categoria.riesgo = "Bajo";
                break;
              case categoria.valor < 45:
                categoria.riesgo = "Medio";
                break;
              case categoria.valor < 60:
                categoria.riesgo = "Alto";
                break;
              case categoria.valor > 59:
                categoria.riesgo = "Muy Alto";
                break;
            }
            break;
          }
          case "Organizacion del tiempo de trabajo": {
            switch (true) {
              case categoria.valor < 5:
                categoria.riesgo = "Nulo o despreciable";
                break;
              case categoria.valor < 7:
                categoria.riesgo = "Bajo";
                break;
              case categoria.valor < 10:
                categoria.riesgo = "Medio";
                break;
              case categoria.valor < 13:
                categoria.riesgo = "Alto";
                break;
              case categoria.valor > 12:
                categoria.riesgo = "Muy Alto";
                break;
            }
            break;
          }
          case "Liderazgo y relaciones en el trabajo": {
            switch (true) {
              case categoria.valor < 14:
                categoria.riesgo = "Nulo o despreciable";
                break;
              case categoria.valor < 29:
                categoria.riesgo = "Bajo";
                break;
              case categoria.valor < 42:
                categoria.riesgo = "Medio";
                break;
              case categoria.valor < 58:
                categoria.riesgo = "Alto";
                break;
              case categoria.valor > 57:
                categoria.riesgo = "Muy Alto";
                break;
            }
            break;
          }
          case "Entorno Organizacional": {
            switch (true) {
              case categoria.valor < 10:
                categoria.riesgo = "Nulo o despreciable";
                break;
              case categoria.valor < 14:
                categoria.riesgo = "Bajo";
                break;
              case categoria.valor < 18:
                categoria.riesgo = "Medio";
                break;
              case categoria.valor < 23:
                categoria.riesgo = "Alto";
                break;
              case categoria.valor > 22:
                categoria.riesgo = "Muy Alto";
                break;
            }
          }
        }
        categoria.dominios.forEach((dominio) => {
          switch (dominio.nombre) {
            case "Condiciones en el ambiente de trabajo": {
              switch (true) {
                case dominio.valor < 5:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 9:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 11:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 14:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 13:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Carga de Trabajo": {
              switch (true) {
                case dominio.valor < 15:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 21:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 27:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 37:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 36:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Falta del Control sobre el trabajo": {
              switch (true) {
                case dominio.valor < 11:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 16:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 21:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 25:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 24:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Jornada de trabajo": {
              switch (true) {
                case dominio.valor < 1:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 2:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 4:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 6:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 5:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Interferencia en la relacion trabajo-familia": {
              switch (true) {
                case dominio.valor < 4:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 6:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 8:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 10:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 9:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Liderazgo": {
              switch (true) {
                case dominio.valor < 9:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 12:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 16:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 20:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 19:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Relaciones en el trabajo": {
              switch (true) {
                case dominio.valor < 10:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 13:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 17:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 21:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 20:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Violencia": {
              switch (true) {
                case dominio.valor < 7:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 10:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 13:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 16:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 15:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Reconocimiento del desempeño": {
              switch (true) {
                case dominio.valor < 6:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case dominio.valor < 10:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 14:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 18:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 17:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
            case "Insuficiente sentido de pertenencia e inestabilidad": {
              switch (true) {
                case dominio.valor < 4:
                  dominio.riesgo = "Nulo o despreciable";
                  break;
                case categoria.valor < 6:
                  dominio.riesgo = "Bajo";
                  break;
                case dominio.valor < 8:
                  dominio.riesgo = "Medio";
                  break;
                case dominio.valor < 10:
                  dominio.riesgo = "Alto";
                  break;
                case dominio.valor > 9:
                  dominio.riesgo = "Muy Alto";
                  break;
              }
              break;
            }
          }
        });
      });
      // enviar resultados
      let objToSend = {
        activo: false,
        calificacion: this.resultados,
        seccion: seccion,
        periodo: this.periodoId,
        encuestaId: this.encuestaId,
        badge: this.$store.getters.badge,
      };
      // console.log(objToSend);
      this.axios
        .post("/nom035/completarEncuesta", objToSend)
        .then((res) => {
          this.$alertify.alertWithTitle(
            "RBC de MEXICO",
            "Gracias por contestar la encuesta.\n Favor de pasar a RECURSOS HUMANOS para completar el tramite.",
            () => {
              if (this.sendMail("recursosHumanos")) {
                this.$router.push("/surveys");
              } else {
                this.$router.push("/surveys");
              }
            }
          );
        })
        .catch((err) => {
          // console.error(err);
        });
    },
    sendMail(tipo) {
      let text =
        "Este es el comprobante de la encuesta, No se necesita atencion medica";
      let data = {
        // to: this.$store.getters.email,
        to: "buzonrbcdemexico@rbcbearings.com",
        subject:
          "RBC de MEXICO - Comprobante de Encuesta - (" +
          this.$store.getters.badge +
          ")  " +
          this.$store.getters.userName,
        text: this.templateATSNoAccion(),
      };
      this.axios
        .post("/email/sendSingleEmail", data)
        .then((res) => {
          return res.data;
        })
        .catch((res) => {
          return false;
        });
    },
    templateATSNoAccion() {
      const fecha = new Date();
      const result1 = new Date().toLocaleDateString("en-GB");
      return (
        `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>Plantilla de correo</title>
</head>
<body>
  <p>Cd. Reynosa, Tamaulipas, a ` +
        fecha.getDate() +
        ` de ` +
        (fecha.getMonth() + 1) +
        `</p>

  <p>RBC de México, S. de R. L. de C. V.</p>
  <p>Departamento de Recursos Humanos</p>
  <p>Presente.</p>

  <p>A quien corresponda:</p>

  <p>El que suscribe, <b>` +
        this.$store.getters.userName +
        `</b>, manifiesto que he sido informado por mi patrón <b>RBC de México, S. de R. L. de C. V.</b> de forma suficiente en relación a la Norma Oficial Mexicana <b>NOM-035-STPS-2018</b>, en la cual se contienen los siguientes numerales:</p>

  <p><b>5.5</b> "El patrón debe identificar a los trabajadores sujetos a acontecimientos traumáticos severos durante o con motivo del trabajo y, canalizarlos para su atención a la institución de seguridad social o privada, o al médico del centro de trabajo o de la empresa"</p>

  <p><b>6.5</b> "El trabajador debe informar por escrito al patrón directamente, a través de los servicios preventivos de seguridad y salud en el trabajo o de la Comisión de Seguridad e Higiene; haber presenciado o sufrido un acontecimiento traumático severo"</p>

  <p>Por lo cual manifiesto haber llenado el "Cuestionario para Identificar a los trabajadores que fueron sujetos a acontecimientos traumáticos severos" el día <b>` +
        result1 +
        `</b>, en el cual informo a mi patrón, que con motivo de mis actividades laborales NO he sufrido un acontecimiento traumático severo.</p>

  <p>También manifiesto haber llenado el "Cuestionario para Identificar los Factores de Riesgo Psicosocial y Evaluar el Entorno Organizacional en los Centros de Trabajo" el día <b>` +
        result1 +
        `</b>.</p>

  <p>Para ambos cuestionarios entiendo que la información será tratada de forma CONFIDENCIAL, y únicamente podrá ser utilizada para los fines relacionados al cumplimiento de las obligaciones de la Norma Oficial Mexicana <b>NOM-035-STPS-2018</b>.</p>
  <br>
  <p>Atentamente,</p>
  <br>
  <p>___________________________________________</p>
  <p>` +
        this.$store.getters.userName +
        ` (` +
        this.$store.getters.badge +
        `)</p>
</body>
</html>
`
      );
    },
  },
};
</script>

<style scoped>
p {
  display: flex;
  flex-wrap: wrap;
  word-break: normal;
  white-space: normal !important;
}
</style>
