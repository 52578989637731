import Vue from "vue";
import Vuex from "vuex";
import decode from "jwt-decode";
import router from "./router";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    usuarioDB: null,
  },
  getters: {
    config(state) {
      return { headers: { token: state.token } };
    },
    auth: (state) => !!state.token,
    user: (state) => (state.usuarioDB ? state.usuarioDB.data : null),
    role: (state) => (state.usuarioDB ? state.usuarioDB.data.role : null),
    userName: (state) =>
      state.usuarioDB ? state.usuarioDB.data.userName : null,
    email: (state) => (state.usuarioDB ? state.usuarioDB.data.email : null),
    badge: (state) => (state.usuarioDB ? state.usuarioDB.data.user : null),
  },
  mutations: {
    setLoginData(state, payload) {
      state.token = payload;
      if (state.token === null) {
        state.usuarioDB = null;
      } else {
        state.usuarioDB = decode(payload);
      }
    },
  },
  actions: {
    handlingServerErrors({ dispatch }, err) {
      if (err.response) {
        switch (err.response.data.mensaje) {
          case "Token expirado":
            dispatch("logout");
            break;
          default:
            // console.error(err.response.data.mensaje)
            break;
        }
      } else {
        // console.error(err)
      }
    },
    logout({ commit }) {
      localStorage.removeItem("ct_token");
      commit("setLoginData", null);
      // console.log('Logged Out')
      router.push("/");
    },
    storeLoginData({ commit }, payload) {
      localStorage.setItem("ct_token", payload);
      commit("setLoginData", payload);
      router.push("/");
    },
    async autoLogin({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("ct_token");
        dispatch("validateToken", token).then((res) => {
          // console.log('Response from validation: ', res)
          if (res) {
            commit("setLoginData", token);
            resolve(true);
          } else {
            localStorage.removeItem("ct_token");
            commit("setLoginData", null);
            resolve(false);
          }
        });
      });
    },
    validateToken({ dispatch }, token) {
      return new Promise((resolve, reject) => {
        if (!token) {
          // console.error('No existe Token')
          resolve(false);
        } else {
          const { exp } = decode(token);
          let fecha = new Date(exp * 1000);
          let hoy = new Date();
          // console.log('Fecha actual: ', hoy)
          // console.log('Token expira el: ', fecha)
          if (hoy > fecha) {
            // console.error('Token expirado el: ', fecha)
            resolve(false);
          } else {
            // console.log('Token Valido')
            resolve(true);
          }
        }
      });
    },
  },
});
