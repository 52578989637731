<template>
  <div class="container">
    <template v-if="seccion1 !== 'Contestada'">
      <div>
        <template v-if="encuesta.seccion">
          <h5 style="color: blue">{{ encuesta.seccion[0].encabezado }}</h5>
          <md-list>
            <md-list-item
              v-for="pregunta in encuesta.seccion[0].preguntas"
              :key="pregunta.id"
            >
              <div class="row">
                <p class="text-left pregunta" sm>
                  {{ pregunta.id }} {{ pregunta.pregunta }}
                </p>
                <div class="col text-center">
                  <div class="my-2" style="width: 200px">
                    <md-button
                      :class="{
                        'md-primary':
                          pregunta.respuesta && pregunta.respuesta !== null,
                      }"
                      @click="updatingValue(pregunta, true)"
                    >
                      SI
                    </md-button>
                    <md-button
                      :class="{
                        'md-primary':
                          !pregunta.respuesta && pregunta.respuesta !== null,
                      }"
                      @click="updatingValue(pregunta, false)"
                    >
                      NO
                    </md-button>
                  </div>
                </div>
              </div>
            </md-list-item>
          </md-list>
          <md-button class="my-3 md-success" @click="submitSectionOne">
            Siguiente...
          </md-button>
        </template>
      </div>
    </template>
    <template v-if="seccion1 === 'Contestada' && seccion2 !== 'Contestada'">
      <div>
        <h5 style="color: blue">
          {{ encuesta.seccion[numSeccion].encabezado }}
        </h5>
        <md-list>
          <md-list-item
            v-for="pregunta in encuesta.seccion[numSeccion].preguntas"
            :key="pregunta.id"
          >
            <div class="row">
              <p class="text-left" sm>
                {{ pregunta.id }} {{ pregunta.pregunta }}
              </p>
              <div class="col">
                <div class="my-2" style="width: 200px">
                  <md-button
                    :class="{
                      'md-primary':
                        pregunta.respuesta && pregunta.respuesta !== null,
                    }"
                    @click="updatingValue(pregunta, true)"
                  >
                    SI
                  </md-button>
                  <md-button
                    :class="{
                      'md-primary':
                        !pregunta.respuesta && pregunta.respuesta !== null,
                    }"
                    @click="updatingValue(pregunta, false)"
                  >
                    NO
                  </md-button>
                </div>
              </div>
            </div>
          </md-list-item>
        </md-list>
        <md-button
          class="my-3 md-success"
          @click="nextSection(numSeccion)"
          v-if="numSeccion < 4"
        >
          Siguiente...
        </md-button>
        <md-button
          class="my-3 md-success"
          @click="submitSectionTwo"
          v-if="numSeccion === 4"
        >
          Siguiente...
        </md-button>
      </div>
    </template>
    <template v-if="seccion1 === 'Contestada' && seccion2 === 'Contestada'">
      <div>
        <h5 style="color: blue">{{ encuesta.seccion[4].encabezado }}</h5>
        <md-list>
          <md-list-item>
            <div class="row">
              <div class="col text-left" sm>
                {{ encuesta.seccion[4].preguntas[0].id }}
                {{ encuesta.seccion[4].preguntas[0].pregunta }}
              </div>
              <div class="col">
                <md-field style="width: 200px">
                  <md-icon>event</md-icon>
                  <md-input
                    type="date"
                    v-model="encuesta.seccion[4].preguntas[0].respuesta"
                  ></md-input>
                </md-field>
              </div>
            </div>
          </md-list-item>
          <md-list-item>
            <div class="row">
              <div class="col text-left" sm>
                {{ encuesta.seccion[4].preguntas[1].id }}
                {{ encuesta.seccion[4].preguntas[1].pregunta }}
              </div>
              <div class="col">
                <md-field>
                  <md-icon>person</md-icon>
                  <md-input
                    v-model="encuesta.seccion[4].preguntas[1].respuesta[0]"
                    autocomplete="off"
                    trim
                  ></md-input>
                </md-field>
                <md-field>
                  <md-icon>person</md-icon>
                  <md-input
                    v-model="encuesta.seccion[4].preguntas[1].respuesta[1]"
                    autocomplete="off"
                    trim
                  ></md-input>
                </md-field>
                <md-field>
                  <md-icon>person</md-icon>
                  <md-input
                    v-model="encuesta.seccion[4].preguntas[1].respuesta[2]"
                    autocomplete="off"
                    trim
                  ></md-input>
                </md-field>
              </div>
            </div>
          </md-list-item>
          <md-list-item>
            <div class="row">
              <div class="col text-left" sm>
                {{ encuesta.seccion[4].preguntas[2].id }}
                {{ encuesta.seccion[4].preguntas[2].pregunta }}
              </div>
              <div class="col">
                <div class="col mt-2">
                  <input
                    type="radio"
                    v-model="radio"
                    value="Seguro Social (IMSS)"
                  />
                  <label>Seguro Social (IMSS)</label>
                </div>
                <div class="col mt-2">
                  <input
                    type="radio"
                    v-model="radio"
                    value="Médico privado de mi preferencia"
                  />
                  <label>Médico privado de mi preferencia</label>
                </div>
              </div>
            </div>
          </md-list-item>
        </md-list>
        <md-button
          class="my-3 md-success"
          @click="finalizarEncuesta"
          v-if="numSeccion < 4"
        >
          Finalizar
        </md-button>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CapturaEventosTraumaticosSeveros",
  props: {
    // periodoId: { type: String, default: '5f7c8b75ded2e4297c26ab14' },
    // encuestaId: { type: String, default: '5f7f4624e1e7e53ca8c4c857' }
    periodoId: { type: String, default: null },
    encuestaId: { type: String, default: null },
  },
  data() {
    return {
      radio: "",
      encuesta: "",
      seccion1: "Incompleta",
      seccion2: "Incompleta",
      numSeccion: 1,
      resultados: {
        seccion1: 0,
        seccion2: 0,
        seccion3: 0,
        seccion4: 0,
        evaluacion: "",
      },
    };
  },
  created() {
    if (this.periodoId === null || this.encuestaId === null) {
      this.$router.push("/surveys");
    }
    this.getEncuesta();
  },
  methods: {
    getEncuesta() {
      this.axios.get("/nom035/getEncuesta/" + this.encuestaId).then((res) => {
        this.encuesta = res.data[0];
        this.encuesta.seccion.map((i) => {
          i.preguntas.map((e) => {
            e.respuesta = null;
          });
        });
      });
    },
    updatingValue(item, value) {
      item.respuesta = value;
      this.$forceUpdate();
    },
    submitSectionOne() {
      let opt = 0;
      let error = false;
      this.encuesta.seccion[0].preguntas.forEach((pregunta) => {
        if (pregunta.respuesta === null) {
          error = true;
          return;
        }
        if (pregunta.respuesta === true) {
          opt++;
        }
      });
      if (error) {
        this.$alertify.alert(
          "RBC de MEXICO",
          "Es necesario contestar todas las preguntas."
        );
        return;
      }

      if (opt === 0) {
        // NINGUN "SI"
        //llenar resultados
        this.resultados.seccion1 = opt;
        this.resultados.evaluacion = "No requiere atención clínica";
        //enviar encuesta
        let seccion = this.encuesta.seccion;
        seccion = seccion.filter((el) => el.seccion === 1);
        seccion.forEach((row) => {
          row.preguntas.forEach((el) => {
            delete el.pregunta;
          });
        });
        let objToSend = {
          activo: false,
          calificacion: this.resultados,
          seccion: seccion,
          periodo: this.periodoId,
          encuestaId: this.encuestaId,
          badge: this.$store.getters.badge,
        };
        // console.log(objToSend);
        this.axios
          .post("/nom035/completarEncuesta", objToSend)
          .then((res) => {
            this.$alertify.alertWithTitle(
              "RBC de MEXICO",
              "Gracias por contestar la encuesta.\n Recibiras un comprobante via Correo",
              () => {
                if (this.sendMail("soloComprobante")) {
                  this.$router.push("/surveys");
                } else {
                  this.$router.push("/surveys");
                }
              }
            );
          })
          .catch((err) => {
            // console.error(err);
          });
      } else {
        // this.$alertify.alert('RBC de MEXICO', 'Sig Encuesta')
        this.resultados.seccion1 = opt;
        this.seccion1 = "Contestada";
      }
    },
    nextSection(sectionIndex) {
      let opt = 0;
      let error = false;
      this.encuesta.seccion[sectionIndex].preguntas.forEach((pregunta) => {
        if (pregunta.respuesta === null) {
          error = true;
          return;
        }
        if (pregunta.respuesta === true) {
          opt++;
        }
      });
      if (error) {
        this.$alertify.alert(
          "RBC de MEXICO",
          "Es necesario contestar todas las preguntas."
        );
        return;
      }
      this.resultados["seccion" + (sectionIndex + 1)] = opt;
      if (this.numSeccion < 3) {
        this.numSeccion++;
      } else {
        if (
          this.resultados.seccion2 >= 1 ||
          this.resultados.seccion3 >= 3 ||
          this.resultados.seccion4 >= 2
        ) {
          this.resultados.evaluacion = "Requiere atención clínica";
          this.encuesta.seccion[4].preguntas[1].respuesta = [];
          this.encuesta.seccion[4].preguntas[2].respuesta = [];
          this.seccion2 = "Contestada";
        } else {
          this.resultados.evaluacion = "No requiere atención clínica";
          let seccion = this.encuesta.seccion;
          seccion.forEach((section) => {
            section.preguntas.forEach((row) => {
              delete row.pregunta;
            });
          });
          let objToSend = {
            activo: false,
            calificacion: this.resultados,
            seccion: seccion,
            periodo: this.periodoId,
            encuestaId: this.encuestaId,
            badge: this.$store.getters.badge,
          };
          this.axios
            .post("/nom035/completarEncuesta", objToSend)
            .then((res) => {
              this.$alertify.alertWithTitle(
                "RBC de MEXICO",
                "Gracias por contestar la encuesta.\n Recibiras un comprobante via Correo",
                () => {
                  if (this.sendMail("soloComprobante")) {
                    this.$router.push("/surveys");
                  } else {
                    this.$router.push("/surveys");
                  }
                }
              );
            })
            .catch((err) => {
              // console.error(err);
            });
        }
      }
    },
    finalizarEncuesta() {
      let error = false;
      if (this.encuesta.seccion[4].preguntas[0].respuesta === null) {
        this.$alertify.alert(
          "RBC de MEXICO",
          "Es necesario especificar la fecha."
        );
        error = true;
      }
      if (
        this.encuesta.seccion[4].preguntas[1].respuesta.length === 0 ||
        this.encuesta.seccion[4].preguntas[1].respuesta[0] === ""
      ) {
        this.$alertify.alert(
          "RBC de MEXICO",
          "Es necesario especificar al menos una persona involucrada."
        );
        error = true;
      }
      this.encuesta.seccion[4].preguntas[2].respuesta[0] = this.radio;
      if (this.encuesta.seccion[4].preguntas[2].respuesta.length === 0) {
        this.$alertify.alert(
          "RBC de MEXICO",
          "Es necesario especificar tipo de atención Médica."
        );
        error = true;
      }
      if (error) {
        return;
      } else {
        // capturar todo y registrar la encuesta
        let seccion = this.encuesta.seccion;
        seccion.forEach((section) => {
          section.preguntas.forEach((row) => {
            delete row.pregunta;
          });
        });
        let objToSend = {
          activo: false,
          calificacion: this.resultados,
          seccion: seccion,
          periodo: this.periodoId,
          encuestaId: this.encuestaId,
          badge: this.$store.getters.badge,
        };
        this.axios
          .post("/nom035/completarEncuesta", objToSend)
          .then((res) => {
            this.$alertify.alertWithTitle(
              "RBC de MEXICO",
              "Gracias por contestar la encuesta.\n Favor de pasar a RECURSOS HUMANOS para completar el tramite.",
              () => {
                if (this.sendMail("recursosHumanos")) {
                  this.$router.push("/surveys");
                } else {
                  this.$router.push("/surveys");
                }
              }
            );
          })
          .catch((err) => {
            // console.error(err);
          });
      }
    },
    sendMail(tipo) {
      let text = "";
      switch (tipo) {
        case "soloComprobante":
          text = this.templateATSNoAccion();

          break;
        case "recursosHumanos":
          text = siEventosSeveros();
          break;
      }
      let data = {
        // to: this.$store.getters.email,
        to: "buzonrbcdemexico@rbcbearings.com",
        subject:
          "RBC de MEXICO - Comprobante de Encuesta - (" +
          this.$store.getters.badge +
          ")  " +
          this.$store.getters.userName,
        text: text,
      };
      this.axios
        .post("/email/sendSingleEmail", data)
        .then((res) => {
          return res.data;
        })
        .catch((res) => {
          return false;
        });
    },
    templateATSNoAccion() {
      const fecha = new Date();
      const result1 = new Date().toLocaleDateString("en-GB");
      return (
        `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>Plantilla de correo</title>
</head>
<body>
  <p>Cd. Reynosa, Tamaulipas, a ` +
        fecha.getDate() +
        ` de ` +
        (fecha.getMonth() + 1) +
        `</p>

  <p>RBC de México, S. de R. L. de C. V.</p>
  <p>Departamento de Recursos Humanos</p>
  <p>Presente.</p>

  <p>A quien corresponda:</p>

  <p>El que suscribe, <b>` +
        this.$store.getters.userName +
        `</b>, manifiesto que he sido informado por mi patrón <b>RBC de México, S. de R. L. de C. V.</b> de forma suficiente en relación a la Norma Oficial Mexicana <b>NOM-035-STPS-2018</b>, en la cual se contienen los siguientes numerales:</p>

  <p><b>5.5</b> "El patrón debe identificar a los trabajadores sujetos a acontecimientos traumáticos severos durante o con motivo del trabajo y, canalizarlos para su atención a la institución de seguridad social o privada, o al médico del centro de trabajo o de la empresa"</p>

  <p><b>6.5</b> "El trabajador debe informar por escrito al patrón directamente, a través de los servicios preventivos de seguridad y salud en el trabajo o de la Comisión de Seguridad e Higiene; haber presenciado o sufrido un acontecimiento traumático severo"</p>

  <p>Por lo cual manifiesto haber llenado el "Cuestionario para Identificar a los trabajadores que fueron sujetos a acontecimientos traumáticos severos" el día <b>` +
        result1 +
        `</b>, en el cual informo a mi patrón, que con motivo de mis actividades laborales NO he sufrido un acontecimiento traumático severo.</p>

  <p>También manifiesto haber llenado el "Cuestionario para Identificar los Factores de Riesgo Psicosocial y Evaluar el Entorno Organizacional en los Centros de Trabajo" el día <b>` +
        result1 +
        `</b>.</p>

  <p>Para ambos cuestionarios entiendo que la información será tratada de forma CONFIDENCIAL, y únicamente podrá ser utilizada para los fines relacionados al cumplimiento de las obligaciones de la Norma Oficial Mexicana <b>NOM-035-STPS-2018</b>.</p>
  <br>
  <p>Atentamente,</p>
  <br>
  <p>___________________________________________</p>
  <p>` +
        this.$store.getters.userName +
        ` (` +
        this.$store.getters.badge +
        `)</p>
</body>
</html>
`
      );
    },
    siEventosSeveros() {
      const fecha = new Date();
      const result1 = new Date().toLocaleDateString("en-GB");
      return (
        `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <title>Plantilla de correo</title>
</head>
<body>
  <p>Cd. Reynosa, Tamaulipas, a ` +
        fecha.getDate() +
        ` de ` +
        (fecha.getMonth() + 1) +
        `</p>
  <br>

  <p>RBC de México, S. de R. L. de C. V.</p>
  <p>Departamento de Recursos Humanos</p>
  <p>Presente.</p>


  <p>A quien corresponda:</p>
  <p>El que suscribe, <b>` +
        this.$store.getters.userName +
        `</b>, manifiesto que he sido informado por mi patrón <b>RBC de México, S. de R. L. de C. V.</b> de forma suficiente en relación a la Norma Oficial Mexicana <b>NOM-035-STPS-2018</b>, en la cual se contienen los siguientes numerales:</p>

  <p><b>5.5</b> "El patrón debe identificar a los trabajadores sujetos a acontecimientos traumáticos severos durante o con motivo del trabajo y, canalizarlos para su atención a la institución de seguridad social o privada, o al médico del centro de trabajo o de la empresa"</p>

  <p><b>6.5</b> "El trabajador debe informar por escrito al patrón directamente, a través de los servicios preventivos de seguridad y salud en el trabajo o de la Comisión de Seguridad e Higiene; haber presenciado o sufrido un acontecimiento traumático severo"</p>

  <p>Por ello, manifiesto haber llenado el <b>"Cuestionario para Identificar los Factores de Riesgo Psicosocial y Evaluar el Entorno Organizacional en los Centros de Trabajo"</b> el día ` +
        result1 +
        `.</p>

  <p>También manifiesto haber llenado el <b>"Cuestionario para Identificar a los trabajadores que fueron sujetos a acontecimientos traumáticos severos"</b> el día` +
        result1 +
        `, en el cual informo a mi patrón, que con motivo de mis actividades laborales SI he sufrido un acontecimiento traumático severo, del cual hago constar lo siguiente:</p>

  <p>Fecha de la ocurrencia del acontecimiento traumático severo:</p>

  <p>Nombre de la(s) persona(s) involucrada(s):</p>
  <p>1.</p>
  <p>2.</p>
  <p>3.</p>

  <p>Es mi deseo ser canalizado para recibir ayuda clínica mediante:</p>
  <p>(     ) Seguridad Social (IMSS)</p>
  <p>(     ) Acudiré a Médico privado de mi preferencia</p>
  <p>(     ) Otro ______________________</p>
  <br>
  <br>
  <p>___________________________________________</p>
  <p>` +
        this.$store.getters.userName +
        ` (` +
        this.$store.getters.badge +
        `)</p>
</body>
</html>
`
      );
    },
  },
};
</script>

<style scoped>
p {
  display: flex;
  flex-wrap: wrap;
  word-break: normal;
  white-space: normal !important;
}
</style>
