<template>
  <md-toolbar
    id="toolbar"
    md-elevation="0"
    class="md-fixed"
    :class="{
      [`md-${type}`]: !navbarTransparent,
      [extraNavClasses]: navbarTransparent,
      'md-transparent': navbarTransparent,
    }"
    :color-on-scroll="colorOnScroll"
  >
    <div class="md-toolbar-row md-collapse-lateral">
      <div class="md-toolbar-section-start">
        <a href="#/" class="md-title">
          <img
            :src="require('@/assets/img/rbcWhite.png')"
            slot="buttons"
            style="width: 50px; margin-top: 0px"
          />
        </a>
        <a href="#/">
          <h3 class="md-title">
            {{ brand }}
          </h3>
        </a>
      </div>
      <div class="md-toolbar-section-end">
        <md-button
          class="md-just-icon md-simple md-toolbar-toggle"
          :class="{ toggled: toggledClass }"
          @click="toggleNavbarMobile()"
        >
          <span class="icon-bar" />
          <span class="icon-bar" />
          <span class="icon-bar" />
        </md-button>

        <div class="md-collapse">
          <div class="md-collapse-wrapper">
            <mobile-menu nav-mobile-section="false">
              <!-- Here you can add your items from the section-start of your toolbar -->
            </mobile-menu>
            <md-list>
              <li class="md-list-item" v-if="user && user.role === 'ADMIN'">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">apps</i>
                        <p>Admin</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a href="#/users">
                            <i class="material-icons">layers</i>
                            Usuarios
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-if="user">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">school</i>
                        <p>Capacitaciones</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li v-for="li in linksSections" :key="li.name">
                          <a :href="'#/' + li.href">
                            <i class="material-icons">{{ li.icon }}</i>
                            {{
                              li.name.charAt(0).toUpperCase() + li.name.slice(1)
                            }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-if="user">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">poll</i>
                        <p>Encuestas</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li>
                          <a :href="'#/surveys'">
                            <i class="material-icons">poll</i>
                            Norma 035
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-if="false">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down">
                      <md-button
                        slot="title"
                        class="md-button md-button-link md-white md-simple dropdown-toggle"
                        data-toggle="dropdown"
                      >
                        <i class="material-icons">view_carousel</i>
                        <p>Examples</p>
                      </md-button>
                      <ul class="dropdown-menu dropdown-with-icons">
                        <li v-for="li in linksExamples" :key="li.name">
                          <a :href="'#/' + li.href">
                            <i class="material-icons">{{ li.icon }}</i>
                            {{ li.name }}
                          </a>
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>

              <li class="md-list-item" v-if="!user">
                <a
                  :href="'#/login'"
                  class="md-list-item-router md-list-item-container md-button-clean"
                >
                  <div class="md-list-item-content">
                    <md-button class="md-success md-round"
                      ><md-icon>account_circle</md-icon>Iniciar
                      Sesion</md-button
                    >
                  </div>
                </a>
              </li>
              <li class="md-list-item" v-if="user">
                <a
                  href="javascript:void(0)"
                  class="md-list-item-router md-list-item-container md-button-clean dropdown"
                >
                  <div class="md-list-item-content">
                    <drop-down direction="down" class="profile-photo">
                      <div
                        slot="title"
                        class="profile-photo-small"
                        data-toggle="dropdown"
                      >
                        <img :src="profilePic" alt="Circle Image" />
                      </div>
                      <ul class="dropdown-menu dropdown-menu-right">
                        <li class="dropdown-header">{{ user.userName }}</li>
                        <!-- <li>
                          <a
                            href="#profile"
                            class="dropdown-item"
                          >Mi Perfil</a>
                        </li>
                        <li>
                          <a
                            href="#"
                            class="dropdown-item"
                          >Configuración</a>
                        </li> -->
                        <li>
                          <a class="dropdown-item" @click="logout"
                            >Cerrar Sesion</a
                          >
                        </li>
                      </ul>
                    </drop-down>
                  </div>
                </a>
              </li>
            </md-list>
          </div>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
let resizeTimeout;
function resizeThrottler(actualResizeHandler) {
  // ignore resize events as long as an actualResizeHandler execution is in the queue
  if (!resizeTimeout) {
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      actualResizeHandler();

      // The actualResizeHandler will execute at a rate of 15fps
    }, 66);
  }
}

import MobileMenu from "@/layout/MobileMenu";
export default {
  components: {
    MobileMenu,
  },
  props: {
    type: {
      type: String,
      default: "white",
      validator(value) {
        return [
          "white",
          "default",
          "primary",
          "danger",
          "success",
          "warning",
          "info",
          "dark",
        ].includes(value);
      },
    },
    colorOnScroll: {
      type: Number,
      default: 0,
    },
    navbarTransparent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      docs_link:
        "https://demos.creative-tim.com/vue-material-kit-pro/documentation/",
      extraNavClasses: "",
      toggledClass: false,
      unknounUser: require("@/assets/img/unknownUser.jpg"),
      brand: "RBC de MÉXICO",
      linksExamples: [
        { name: "About Us", href: "about-us", icon: "account_balance" },
        { name: "Blog Post", href: "blog-post", icon: "art_track" },
        { name: "Blog Posts", href: "blog-posts", icon: "view_quilt" },
        { name: "Contact Us", href: "contact-us", icon: "location_on" },
        { name: "Landing Page", href: "landing-page", icon: "view_day" },
        { name: "Login Page", href: "login-page", icon: "fingerprint" },
        { name: "Pricing Page", href: "pricing-page", icon: "attach_money" },
        {
          name: "Shopping Cart",
          href: "shopping-cart",
          icon: "shopping_basket",
        },
        { name: "Ecommerce Page", href: "ecommerce-page", icon: "store" },
        { name: "Product Page", href: "product-page", icon: "shopping_cart" },
        { name: "Profile Page", href: "profile-page", icon: "account_circle" },
        { name: "Signup Page", href: "signup-page", icon: "person_add" },
        { name: "Error Page", href: "error-page", icon: "error" },
      ],
    };
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    profilePic() {
      if (!this.user.profilePic || this.user.profilePic === "") {
        return this.unknounUser;
      } else {
        return `data:image/png;base64,${this.user.profilePic}`;
      }
    },
    linksSections() {
      var arr = [
        { name: "Generales", href: "entrenamientosGenerales", icon: "public" },
      ];
      var departamentos = [
        {
          depCode: 400,
          department: "Recursos Humanos",
          href: "entrenamientosDepartamentales",
          icon: "group",
        },
        {
          depCode: 500,
          department: "Ingeniería",
          href: "entrenamientosDepartamentales",
          icon: "build",
        },
      ];
      if (this.user) {
        var item = departamentos.find(
          (el) => parseInt(el.depCode) === parseInt(this.user.depCode)
        );
        if (item) {
          arr.push({
            name: item.department,
            href: item.href,
            icon: item.icon,
          });
        }
      }
      return arr;
    },
  },
  mounted() {
    document.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.scrollListener);
  },
  methods: {
    bodyClick() {
      let bodyClick = document.getElementById("bodyClick");

      if (bodyClick === null) {
        let body = document.querySelector("body");
        let elem = document.createElement("div");
        elem.setAttribute("id", "bodyClick");
        body.appendChild(elem);

        let bodyClick = document.getElementById("bodyClick");
        bodyClick.addEventListener("click", this.toggleNavbarMobile);
      } else {
        bodyClick.remove();
      }
    },
    toggleNavbarMobile() {
      this.NavbarStore.showNavbar = !this.NavbarStore.showNavbar;
      this.toggledClass = !this.toggledClass;
      this.bodyClick();
    },
    handleScroll() {
      let scrollValue =
        document.body.scrollTop || document.documentElement.scrollTop;
      let navbarColor = document.getElementById("toolbar");
      this.currentScrollValue = scrollValue;
      if (this.colorOnScroll > 0 && scrollValue > this.colorOnScroll) {
        this.extraNavClasses = `md-${this.type}`;
        navbarColor.classList.remove("md-transparent");
      } else {
        if (this.extraNavClasses) {
          this.extraNavClasses = "";
          navbarColor.classList.add("md-transparent");
        }
      }
    },
    scrollListener() {
      resizeThrottler(this.handleScroll);
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
};
</script>
