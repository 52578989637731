<template>
  <footer
    class="footer"
    :class="{
      [`footer-${type}`]: type,
      [`footer-${size}`]: size
    }"
    :data-background-color="backgroundColor"
  >
    <!-- <template v-if="$route.name !== 'ecommerce-page' && $route.name !== 'presentation'"> -->
    <template>
      <div class="container">
        <nav>
          <ul>
            <li>
              <a href="#/">
                RBC de México
              </a>
            </li>
            <li>
              <a href="https://www.rbcbearings.com" target="_blank">
                RBC Bearings Co
              </a>
            </li>
          </ul>
        </nav>
        <div class="copyright">
          &copy; {{ year }}, Coded by César Trujillo
        </div>
      </div>
    </template>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: { type: String, default: 'green' },
    type: String,
    size: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
