<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter clear-filter"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="md-layout">
        <div class="md-layout-item">
          <div class="image-wrapper">
            <div class="brand">
              <h1>
                RBC de MEXICO
              </h1>
              <span class="pro-badge">
                Reynosa
              </span>
            </div>
          </div>
        </div>
      </div>
    </parallax>
    <div id="main-panel" class="main main-raised">
      <div class="section">
        <div class="container">
          <h1 class='title text-center'>
            Nuestra política de Calidad
          </h1>
          <img
            :src="politicaCalidad"
            alt="Raised Image"
            class="img-raised rounded"
            :class="{ 'responsive-image': responsive }"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";

export default {
  name: "Index",
  components: {
  },
  mixins: [Mixins.VerticalNav, Mixins.HeaderImage],
  bodyClass: "index-page",
  props: {
    image: {
      type: String,
      // default: require("@/assets/img/main_building.png")
      default: require("@/assets/img/blueboard.jpg")
    }
  },
  data() {
    return {
      politicaCalidad: require('@/assets/img/politica_calidad.png')
    }
  },
  methods: {
  }
};
</script>
<style lang="scss">
@media all and (min-width: 991px) {
  .btn-container {
    display: flex;
  }
}
@media all and (max-width: 768px) {
  .vertical-nav-active {
    display: none;
  }
}
</style>
