<template>
  <div class="wrapper">
    <parallax
      class="section page-header header-filter header-mini"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="image-wrapper">
        <div class="brand">
          <h1>Usuarios</h1>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section profile-content">
        <div class="container">
          <div class="md-layout">
            <div class="md-layout-item md-size-100 md-small-size-100 mx-auto">
              <div class="md-layout">
                <div class="md-layout-item md-size-90 mx-left">
                  <el-input placeholder="Escribe para buscar" v-model="search" class="input-with-select" style='margin-top:15px;'>
                    <template slot="prepend">
                      <el-select v-model="filter" placeholder="Filtros">
                        <el-option v-for="(item, index) in fields" :key="index" :label="item.label" :value="item.key"></el-option>
                      </el-select>
                    </template>
                    <template slot="append">
                      <md-icon>search</md-icon>
                    </template>
                  </el-input>
                </div>
                <div class='md-layout-item md-size-10 mx-right' style='margin-top:10px;'>
                  <md-button class="md-primary" @click="newAccountModal"><i class="material-icons">person_add</i></md-button>
                </div>
              </div>
              <div style='margin-top:15px;'>
                <el-table :data="filteredData" height="500" id='miTabla' @sort-change="sortHandler" @filter-change="filterChangeHandler">
                  <el-table-column
                    prop="user"
                    sortable
                    label="Numero"
                    fixed="left"
                    width="100">
                  </el-table-column>
                  <el-table-column
                    prop="userName"
                    sortable
                    label="Nombre de Usuario"
                    width='350'>
                  </el-table-column>
                  <el-table-column
                    prop="area"
                    sortable
                    label="Area"
                    :filters="areaFilters"
                    :filter-method="filterHandler"
                    filter-placement="bottom-end"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="department"
                    sortable
                    label="Departamento"
                    :filters="depFilters"
                    :filter-method="filterHandler"
                    filter-placement="bottom-end"
                    width="200">
                  </el-table-column>
                  <el-table-column
                    prop="email"
                    label="Correo Electrónico"
                    width="300">
                  </el-table-column>
                  <el-table-column
                    prop="activo"
                    sortable
                    label="Estado"
                    width='90'
                    fixed="right">
                    <template slot-scope="scope">
                      <md-switch v-model="scope.row.activo" class='md-primary' @change="handleSwitch(scope.row)"></md-switch>
                    </template>
                  </el-table-column>
                  <el-table-column align="right" label='Opciones' fixed="right" width='120'>
                    <template slot-scope="scope">
                      <md-button size="sm" @click="openModal(scope.row)" class='md-success md-sm'>Editar</md-button>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="block">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pagination.currentPage"
                    :page-sizes="[20, 50, 100, 200, 300, 400, 500, 1000]"
                    :page-size="pagination.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="pagination.total">
                  </el-pagination>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title='Editar Usuario' :visible.sync="modal" width="500px">
      <md-field>
        <label for="">Número</label>
        <md-input v-model="modalData.user" disabled></md-input>
      </md-field>
      <md-field>
        <label for="">Nombre del Empleado</label>
        <md-input v-model="modalData.userName"></md-input>
      </md-field>
      <div style='margin-bottom:15px;'>
        <label for="">Area</label>
        <el-select v-model="modalData.area" style='width:100%;'>
          <el-option v-for="(item, index) in areas" :key="index" :value="item.label">{{item.label}}</el-option>
        </el-select>
      </div>
      <div style='margin-bottom:15px;'>
        <label for="">Departamento</label>
        <el-select v-model="modalData.department" style='width:100%;'>
          <el-option v-for="(item, index) in departamentos" :key="index" :value="item.label">{{item.label}}</el-option>
        </el-select>
      </div>
      <md-field>
        <label for="">Correo Electrónico</label>
        <md-input v-model="modalData.email"></md-input>
      </md-field>
      <div>
        <label for="">Rol</label>
        <el-select v-model="modalData.role"  style='width:100%;'>
          <el-option value="USER">Empleado</el-option>
          <el-option value="EXTERNAL">Externo</el-option>
          <el-option value="ADMIN">Adminisrador</el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <md-button class='md-primary md-sm pull-left' @click="pwdReset">Pwd Reset</md-button>
        <md-button class="md-danger md-sm" @click="modal = false">Cancelar</md-button>
        <md-button class="md-primary md-sm" @click="confirmDialog">Confirmar</md-button>
      </span>
    </el-dialog>
    <el-dialog title='Nuevo Usuario' :visible.sync="newModal" width="500px">
      <md-field>
        <label for="">Número</label>
        <md-input v-model="newModalData.user"></md-input>
      </md-field>
      <md-field>
        <label for="">Contraseña</label>
        <md-input v-model="newModalData.pass"></md-input>
      </md-field>
      <md-field>
        <label for="">Nombre del Empleado</label>
        <md-input v-model="newModalData.userName"></md-input>
      </md-field>
      <div style='margin-bottom:15px;'>
        <label for="">Area</label>
        <el-select v-model="newModalData.area" style='width:100%;'>
          <el-option v-for="(item, index) in areas" :key="index" :value="item.label">{{item.label}}</el-option>
        </el-select>
      </div>
      <div style='margin-bottom:15px;'>
        <label for="">Departamento</label>
        <el-select v-model="newModalData.department" style='width:100%;'>
          <el-option v-for="(item, index) in departamentos" :key="index" :value="item.label">{{item.label}}</el-option>
        </el-select>
      </div>
      <md-field>
        <label for="">Correo Electrónico</label>
        <md-input v-model="newModalData.email"></md-input>
      </md-field>
      <div>
        <label for="">Rol</label>
        <el-select v-model="newModalData.role"  style='width:100%;'>
          <el-option value="USER">Empleado</el-option>
          <el-option value="EXTERNAL">Externo</el-option>
          <el-option value="ADMIN">Adminisrador</el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <md-button class="md-danger md-sm" @click="newModal = false">Cancelar</md-button>
        <md-button class="md-primary md-sm" @click="confirmNewDialog">Confirmar</md-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { mapGetters } from 'vuex'
import { Select, Option, Table, TableColumn, Input, Pagination, Loading, Dialog, Message } from 'element-ui'
export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Pagination.name]: Pagination,
    [Dialog.name]: Dialog
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "profile-page",
  data() {
    return {
      image: require("@/assets/img/users-background.jpg"),
      items: [],
      filter: 'userName',
      search: '',
      fields: [
        { key: 'user', label: 'Numero' },
        { key: 'userName', label: 'Nombre' },
        { key: 'area', label: 'Area' },
        { key: 'department', label: 'Departamento' },
        { key: 'email', label: 'Correo Electronico' }
      ],
      pagination: {
        currentPage: 1,
        pageSize: 20,
        sortField: 'user',
        sortOrder: 1,
        total: 100
      },
      modal: false,
      newModal: false,
      modalData: {},
      newModalData: {
        user: '',
        userName: '',
        pass: '',
        area: '',
        department: '',
        email: '',
        role: 'USER'
      },
      areas: [
        { key: "501", label: "INGENIERIA" },
        { key: "104", label: "ESFERICOS" },
        { key: "107", label: "SPB CNC" },
        { key: "601", label: "ASEGURAMIENTO DE CALIDAD" },
        { key: "901", label: "IT" },
        { key: "102", label: "ENSAMBLE" },
        { key: "108", label: "RYAT MAGNA" },
        { key: "106", label: "BBRE" },
        { key: "802", label: "PROYECTOS" },
        { key: "201", label: "MANTENIMIENTO" },
        { key: "302", label: "PLANEACION" },
        { key: "304", label: "IMPORT/EXPORT" },
        { key: "001", label: "GERENCIA" },
        { key: "111", label: "ACABADOS SUPERFICIALES" },
        { key: "105", label: "CAM FOLLOWERS" },
        { key: "109", label: "CAM FOLLOWERS ASSY" },
        { key: "801", label: "FACILITIES" },
        { key: "401", label: "RECURSOS HUMANOS" },
        { key: "103", label: "AUTOMATICOS" },
        { key: "303", label: "COMPRAS" },
        { key: "110", label: "GRINDING" },
        { key: "112", label: "787 RODS ASSY" },
        { key: "951", label: "VENTAS" },
        { key: "402", label: "FINANZAS" },
        { key: "602", label: "SISTEMA DE CALIDAD" },
        { key: "101", label: "CNC" },
        { key: "301", label: "MATERIALES" }
      ],
      departamentos: [
        { key: '001', label: 'DIRECCION' },
        { key: '100', label: 'PRODUCCION' },
        { key: '200', label: 'MANTENIMIENTO' },
        { key: '300', label: 'MATERIALES' },
        { key: '400', label: 'RECURSOS HUMANOS' },
        { key: '500', label: 'INGENIERIA' },
        { key: '600', label: 'CALIDAD' },
        { key: '700', label: 'FINANZAS' },
        { key: '800', label: 'FACILITIES/PROYECTOS' },
        { key: '900', label: 'IT' },
        { key: '950', label: 'VENTAS' },
      ]
    }
  },
  computed: {
    ...mapGetters(['config']),
    filteredData () {
      return this.items.filter(el => el[this.filter].toLowerCase().includes(this.search.toLowerCase()))
    },
    depFilters () {
      if (this.items.length > 0) {
        let arr = [...new Set(this.items.map(item => item.department))]
        let aus = []
        arr.forEach(el => {
          aus.push({ value: el, text: el })
        })
        return aus
      } else {
        return []
      }
    },
    areaFilters () {
      if (this.items.length > 0) {
        let arr = [...new Set(this.items.map(item => item.area))]
        let aus = []
        arr.forEach(el => {
          aus.push({ value: el, text: el })
        })
        return aus
      } else {
        return []
      }
    }
  },
  created () {
    this.pullUsers()
  },
  methods: {
    pullUsers () {
      var loadingInstance = Loading.service({ target: '#miTabla', text: 'Cargando Información.' })
      let options = {
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
        sort: { [this.pagination.sortField]: this.pagination.sortOrder }, 
        select: 'user userName area department email activo role'
      }
      this.axios.post('/users/', options).then(res => {
        this.items = res.data.docs
        this.pagination.total = res.data.totalDocs
        loadingInstance.close()
      })
    },
    edit (row) {
      console.log(row)
      return null
    },
    filterHandler (value, row, column) {
      if (column === undefined) {
        return null
      } else {
        const property = column['property']
        return row[property] === value
      }
    },
    handleSizeChange(val) {
      this.pagination.pageSize = val
      this.pullUsers()
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val
      this.pullUsers()
    },
    sortHandler( column) {
      this.pagination.sortField = column.prop
      this.pagination.sortOrder = column.order
      this.pullUsers()
    },
    filterChangeHandler(filters) {
      console.log(filters)
    },
    openModal (item) {
      this.modalData = item
      this.modal = true
    },
    newAccountModal (item) {
      for (let i in this.newModalData) {
        this.newModalData[i] = ''
      }
      this.newModalData.role = 'USER'
      this.newModal = true
    },
    confirmDialog () {
      var loadingInstance = Loading.service({ target: '#miTabla', text: 'Cargando Información.' })
      this.modalData.areaCode = this.areas.find(el => el.label === this.modalData.area).key
      this.modalData.depCode = this.departamentos.find(el => el.label === this.modalData.department).key
      this.axios.put('/users/basicUpdate/' + this.modalData._id, this.modalData, this.config).then(res => {
        if (res.data.success){
          loadingInstance.close()
        } else {
          loadingInstance.close()
          console.error(res.data.error)
        }
      })
      this.modal = false
    },
    confirmNewDialog () {
      var error = 0
      for (let i in this.newModalData) {
        if (this.newModalData[i] === '') {
          error++
          console.error('El campo ' + i + ' esta vacío.')
        }
      }
      if (error === 0) {
        console.log(this.newModalData)
        var loadingInstance = Loading.service({ target: '#miTabla', text: 'Cargando Información.' })
        this.newModalData.areaCode = this.areas.find(el => el.label === this.newModalData.area).key
        this.newModalData.depCode = this.departamentos.find(el => el.label === this.newModalData.department).key
        console.log(this.newModalData)
        this.axios.post('/users/nuevo-usuario/', this.newModalData, this.config).then(res => {
          if (res.data.success){
            this.items.push(res.data.userDB)
            loadingInstance.close()
          } else {
            loadingInstance.close()
            console.error(res.data.error)
          }
          this.newModal = false
        })
      } else {

      }
    },
    handleSwitch (item) {
      this.axios.put('/users/updateStatus/' + item._id, { activo: item.activo }, this.config).then(res => {
        if (!res.data.success){
          console.error(res.data.error)
        }
      })
    },
    pwdReset () {
      this.axios.put('/users/pwdUpdate/' + this.modalData._id, { pass: this.modalData.user, reset: true }, this.config).then(res => {
        Message({
          message: 'Contraseña iniciada a ' + this.modalData.user,
          center: true,
          type: 'success'
        })
      })
      return false
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  padding: 0;
}
.brand{
    color: white;
    text-align: center;
    position: relative;
    z-index: 999;

    h1{
        font-size: 6rem;
        font-weight: 600;
        display: inline-block;
        position: relative;
        line-height: 1.15em;
        text-shadow: 0px 15px 102.86px rgba(0, 0, 0, 0.7), 0px 15px 40.02px rgba(0, 0, 0, 0.21);
    }
    h3{
      font-size: 1.313rem;
      max-width: 500px;
      margin: 10px auto 0;
      text-shadow: 0px 15px 102.86px rgba(0, 0, 0, 0.9), 0px 15px 40.02px rgba(0, 0, 0, 0.51);
    }
}
.el-input {
  .el-select {
    width: 200px;
  }
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>
