var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('parallax',{staticClass:"page-header header-filter",style:(_vm.headerStyle),attrs:{"parallax-active":"false"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-80 md-small-size-100 mx-auto text-center"},[_c('md-card',{staticClass:"md-card md-card-signup"},[_c('md-card-content',[_c('h2',{staticClass:"card-title text-center"},[_vm._v(" "+_vm._s(_vm.cuestionario.nombre)+" ")]),(
                  !_vm.iniciado &&
                  Object.keys(_vm.cuestionario).length === 0 &&
                  !_vm.mostrarResultado
                )?[_c('center',[_c('md-progress-spinner',{attrs:{"md-mode":"indeterminate","md-diameter":120,"md-stroke":10}})],1)]:_vm._e(),(
                  !_vm.iniciado &&
                  Object.keys(_vm.cuestionario).length > 0 &&
                  !_vm.mostrarResultado
                )?[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100 ml-auto"},[_c('p',[_vm._v(" Este cuestionario esta compuesto de "+_vm._s(_vm.cuestionario.preguntas.length)+" preguntas y requiere de un mínimo de calificacion de un "+_vm._s(_vm.cuestionario.minimumScore)+" %. ")]),_c('p',[_vm._v(" Solo debes seleccionar la opción que consideras correcta para proceder a la siguiente pregunta. ")]),_c('p',[_vm._v(" Presiona el botón de iniciar para proceder a la primer pregunta. ")])])]),_c('md-button',{staticClass:"md-just-icon md-primary md-round pull-left",on:{"click":_vm.goBack}},[_c('md-icon',[_vm._v("arrow_back")])],1),_c('md-button',{staticClass:"md-primary md-round pull-right",on:{"click":_vm.iniciarCuestionario}},[_c('md-icon',[_vm._v("border_color")]),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("Iniciar")])],1)]:_vm._e(),(_vm.iniciado && !_vm.mostrarResultado)?[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100 ml-auto"},[_c('full-bg-card',{attrs:{"card-image":require('@/assets/img/questions.png')}},[_c('template',{slot:"cardContent"},[_c('h3',{staticClass:"card-title",staticStyle:{"margin-top":"30px"}},[_vm._v(" "+_vm._s(_vm.cuestionario.preguntas[_vm.questionIndex].pregunta)+" ")]),_vm._l((_vm.cuestionario.preguntas[
                            _vm.questionIndex
                          ].opciones),function(item,index){return _c('div',{key:index,staticClass:"options",class:{
                            active:
                              _vm.cuestionario.preguntas[_vm.questionIndex]
                                .respuestaUsuario === item.opt,
                          },on:{"click":function($event){return _vm.grabarRespuesta(item.opt)}}},[_c('p',{staticStyle:{"text-align":"left","padding":"10px 25px 10px 25px"}},[_vm._v(" "+_vm._s(item.label)+" ")])])})],2)],2),_c('p',{staticClass:"description"},[_vm._v(" Pregunta "+_vm._s(_vm.questionIndex + 1)+" de "+_vm._s(_vm.cuestionario.preguntas.length)+" ")])],1)]),_c('md-button',{staticClass:"md-just-icon md-primary md-round pull-left",attrs:{"disabled":_vm.questionIndex === 0},on:{"click":_vm.prevQuestion}},[_c('md-icon',[_vm._v("arrow_back")])],1),(_vm.questionIndex !== _vm.cuestionario.preguntas.length - 1)?_c('md-button',{staticClass:"md-just-icon md-primary md-round pull-right",on:{"click":_vm.nextQuestion}},[_c('md-icon',[_vm._v("arrow_forward")])],1):_vm._e(),(_vm.questionIndex === _vm.cuestionario.preguntas.length - 1)?_c('md-button',{staticClass:"md-primary md-round pull-right",on:{"click":_vm.finalizarCuestionario}},[_c('md-icon',[_vm._v("check")]),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("Finalizar")])],1):_vm._e()]:_vm._e(),(_vm.mostrarResultado)?[_c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-size-100 md-small-size-100 ml-auto"},[_c('h5',[_vm._v("Gracias por completar el cuestionario!")]),_c('h5',[_vm._v(" Has concluido el cuestionario con "+_vm._s(_vm.correctas)+" preguntas correctas de un total de "+_vm._s(_vm.cuestionario.preguntas.length)+". ")]),_c('h5',[_vm._v(" Se necesita un mínimo del "+_vm._s(_vm.cuestionario.minimumScore)+"% para acreditar, y tu calificación es del "+_vm._s(_vm.objToSend.calificacion)+"% por tanto: ")]),(
                        _vm.objToSend.calificacion >= _vm.cuestionario.minimumScore
                      )?[_c('h1',{staticClass:"text-success"},[_vm._v("ACREDITASTE!")]),_c('h5',[_vm._v(" Puedes volver a ver la capacitación cuantas veces gustes en caso de que lo necesites! ")]),_c('md-button',{staticClass:"md-primary md-round",on:{"click":_vm.goToTrainings}},[_c('md-icon',[_vm._v("school")]),_vm._v(" Tomar otro curso ")],1)]:_vm._e(),(
                        _vm.objToSend.calificacion < _vm.cuestionario.minimumScore
                      )?[_c('h1',{staticClass:"text-danger"},[_vm._v("NO ACREDITASTE!")]),_c('h5',[_vm._v(" Puedes volver a tomar la capacitacion y presentar de nuevo el cuestionario!! ")]),_c('md-button',{staticClass:"md-primary md-round",on:{"click":_vm.goBack}},[_c('md-icon',[_vm._v("arrow_back")]),_vm._v(" Regresar al Video ")],1)]:_vm._e()],2)])]:_vm._e()],2)],1)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }