<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="">Entrenamientos de {{ user.department }}</h1>
            <h4>
              La Capacitación nunca ha sido un gasto, la verdadera Capacitación
              siempre es una inversión.
            </h4>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-team pb-0">
        <div
          class="container"
          style="margin-top: 10px; height: 200px"
          v-if="listadoVideos.length === 0"
        >
          <center>
            <md-progress-spinner
              md-mode="indeterminate"
              :md-diameter="150"
              :md-stroke="10"
            ></md-progress-spinner>
          </center>
        </div>
        <div class="container" v-if="listadoVideos.length > 0">
          <div
            class="md-layout mt-2"
            v-for="(item, index) in listadoVideos"
            :key="index"
          >
            <div class="md-layout-item md-size-25 md-small-size-100 mx-auto">
              <profile-card
                card-plain
                card-avatar
                :shadow-normal="false"
                :no-colored-shadow="false"
                :card-image="profilePic(item.instructorUser)"
                style="margin-top: 50px"
              >
                <template slot="cardContent">
                  <h4 class="card-title" v-if="item.instructorUser.length > 0">
                    {{ item.instructorUser[0].userName }}
                  </h4>
                  <h4
                    class="card-title"
                    v-if="item.instructorUser.length === 0"
                  >
                    Desconocido
                  </h4>
                  <h6 class="card-category text-muted">Instructor</h6>
                </template>
              </profile-card>
            </div>
            <div class="md-layout-item md-size-75 md-small-size-100">
              <h2
                style="margin-top: 0px; cursor: pointer"
                @click="openVideo(item)"
              >
                {{ item.label }}
              </h2>
              <h4 v-if="item.type === 'evaluacion'">EVALUACION!!</h4>
              <h4 style="padding-top: 15px" class="description">
                {{ item.descripcion }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProfileCard } from "@/components";
import Mixins from "@/plugins/basicMixins";
import { mapGetters } from "vuex";
export default {
  components: {
    ProfileCard,
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      listadoVideos: [],
      // image: require("@/assets/img/bg9.jpg"),
      image: require("@/assets/img/blueboard.jpg"),
      unknownInstructor: require("@/assets/img/unknownUser.jpg"),
    };
  },
  computed: {
    ...mapGetters(["config", "user"]),
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    if (this.listadoVideos.length === 0) {
      this.axios
        .post(
          "/videoDifusion/getVideosDepartamentales/",
          { user: this.user._id },
          this.config
        )
        .then((res) => {
          for (let video in res.data) {
            this.listadoVideos.push(res.data[video]);
          }
        })
        .catch((err) => {
          this.$store.dispatch("handlingServerErrors", err);
        });
    }
  },
  methods: {
    openVideo(item) {
      if (item.type === "evaluacion") {
        this.$router.push({
          name: "videoEvaluacion",
          params: { videoId: item._id },
        });
      } else {
        this.$router.push({ name: "videos", params: { videoId: item._id } });
      }
    },
    profilePic(user) {
      if (user.length > 0 && user[0].profilePic && user[0].profilePic !== "") {
        return `data:image/png;base64,${user[0].profilePic}`;
      } else {
        return this.unknownInstructor;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
