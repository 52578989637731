<template>
  <div class="wrapper">
    <div
      class="section page-header"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-40 md-small-size-66 md-xsmall-size-100 md-medium-size-40 mx-auto"
          >
            <login-card header-color="blue">
              <img :src="require('@/assets/img/rbcWhite.png')" slot="buttons" style='width:100px; margin-top:5px; margin-bottom: 20px;'>
              <p
                slot="description"
                class="description"
              >
                Inicia Sesión
              </p>
              <template v-if="!updatePassword">
                <form @submit.prevent="login" slot="inputs">
                  <center>
                    <md-field
                      slot="inputs"
                      class="md-form-group"
                    >
                      <md-icon>face</md-icon>
                      <label>Usuario...</label>
                      <md-input v-model="user" />
                    </md-field>
                    <md-field
                      slot="inputs"
                      class="md-form-group"
                    >
                      <md-icon>lock_outline</md-icon>
                      <label>Contraseña...</label>
                      <md-input v-model="password" type='password' />
                    </md-field>
                    <div class='md-form-group' slot="inputs">
                      <c-alert type="danger" v-if="loginError" text="Datos incorrectos"></c-alert>
                      <c-alert type="danger" v-if="formError" text="Datos incompletos"></c-alert>
                    </div>

                    <md-progress-spinner slot="footer" md-mode="indeterminate" v-if="isLoading" :md-diameter="40" :md-stroke="3" style='margin-top: 10px;'></md-progress-spinner>
                    <md-button slot="footer" class="md-simple md-primary md-lg" type="submit" v-if="!isLoading">Iniciar Sesión</md-button>
                  </center>
                </form>
              </template>
              <template v-if="updatePassword">
                <form @submit.prevent="passUpdate" slot="inputs">
                  <center>
                  <md-field
                    slot="inputs"
                    class="md-form-group"
                  >
                    <md-icon>lock_outline</md-icon>
                    <label>Nueva Contraseña...</label>
                    <md-input v-model="newPassword" type='password' />
                  </md-field>
                  <md-field
                    slot="inputs"
                    class="md-form-group"
                  >
                    <md-icon>lock_outline</md-icon>
                    <label>Confirma Nueva Contraseña...</label>
                    <md-input v-model="newPassword2" type='password' />
                  </md-field>
                  <div class='md-form-group' slot="inputs">
                    <c-alert type="danger" v-if="matchError" text="Contraseñas no coinciden"></c-alert>
                    <c-alert type="danger" v-if="formError" text="Datos incompletos"></c-alert>
                    <c-alert type="danger" v-if="serverError" text="Error en servidor"></c-alert>
                  </div>

                  <md-progress-spinner slot="footer" md-mode="indeterminate" v-if="isLoading" :md-diameter="40" :md-stroke="3" style='margin-top: 10px;'></md-progress-spinner>
                  <md-button
                    v-if="!isLoading"
                    slot="footer"
                    class="md-simple md-primary md-lg"
                    type="submit"
                  >
                    Actualizar Contraseña
                  </md-button>
                  </center>
                </form>
              </template>
            </login-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginCard } from "@/components";
import Mixins from "@/plugins/basicMixins";
import Alert from '@/components/Alert.vue'
export default {
  components: {
    LoginCard,
    'c-alert': Alert
  },
  mixins: [Mixins.HeaderImage],
  bodyClass: "login-page",
  data() {
    return {
      image: require("@/assets/img/blueboard.jpg"),
      isLoading: false,
      id: '',
      user: '',
      password: '',
      loginError: false,
      formError: false,
      matchError: false,
      serverError: false,
      newPassword: '',
      newPassword2: '',
      updatePassword: false,
      token: null
    };
  },
  computed: {
    pass () {
      return this.password.toUpperCase()
    }
  },
  methods: {
    login () {
      this.isLoading = true
      if (this.validateForm()) {
        let usuario = {
          user: this.user,
          pass: this.pass
        }
        this.axios.post('/login', usuario).then(res => {
            let token = res.data.token
            if (res.data.usuarioDB.firstLogin) {
              this.updatePassword = true
              this.token = res.data.token
              this.id = res.data.usuarioDB._id
              this.isLoading = false
            } else {
              this.$store.dispatch('storeLoginData', token)
            }
        }).catch(err => {
          this.loginError = true
          this.$store.dispatch('handlingServerErrors', err)
          this.isLoading = false
        })
      } else {
        this.formError = true
        this.isLoading = false
      }
      return null
    },
    passUpdate () {
      this.isLoading = true
      if (this.validateForm2()) {
        this.axios.put('/users/pwdUpdate/' + this.id, { pass: this.newPassword }, { headers: { token: this.token } }).then(res => {
          if (res.data.success) {
            this.$store.dispatch('storeLoginData', this.token)
          } else {
            this.serverError = true
            this.isLoading = false
          }
        }).catch(err => {
          this.serverError = true
          this.isLoading = false
          console.log(err)
        })
      } else {
        this.isLoading = false
      }
    },
    validateForm () {
      this.formError = false
      this.loginError = false
      if (this.user === '') {
        return false
      }
      if (this.password === '') {
        return false
      }
      return true
    },
    validateForm2 () {
      this.formError = false
      this.matchError = false
      if (this.newPassword === '') {
        this.formError = true
        return false
      }
      if (this.newPassword2 === '') {
        this.formError = true
        return false
      }
      if (this.newPassword !== this.newPassword2) {
        this.matchError = true
        return false
      }
      return true
    }
  }
};
</script>

<style lang="css"></style>
