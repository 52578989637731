// =========================================================
// * Vue Material Kit PRO - v1.3.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vue-material-kit-pro
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from "vue";
import App from "./App.vue";
// import { Message } from 'element-ui';
import alertify from "vue-alertify";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import lang from "element-ui/lib/locale/lang/es";
import locale from "element-ui/lib/locale";

import MaterialKit from "./plugins/material-kit";

Vue.config.productionTip = false;
// configure language
locale.use(lang);

Vue.use(MaterialKit);
// Vue.use(Message)
Vue.use(VueAxios, axios);

Vue.use(alertify, {
  glossary: {
    title: "ATOM",
    ok: "OK",
    cancel: "Cancel",
  },
});

// target can be 'development' or 'production'
if (process.env.NODE_ENV === "development") {
  axios.defaults.baseURL = "http://localhost:3000/api";
} else {
  if (location.protocol === "https:") {
    axios.defaults.baseURL = "https://www.rbcdemexico.com/api";
  } else {
    window.location.href = window.location.href.replace("http:", "https:");
    axios.defaults.baseURL = "https://www.rbcdemexico.com/api";
  }
}
// axios.defaults.baseURL = "https://www.rbcdemexico.com/api";

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
