<template>
  <div class="wrapper">
    <parallax
      class="page-header header-filter header-small"
      parallax-active="true"
      :style="headerStyle"
    >
      <div class="container">
        <div class="md-layout">
          <div
            class="md-layout-item md-size-66 md-small-size-100 mx-auto text-center"
          >
            <h1 class="">Encuestas</h1>
          </div>
        </div>
      </div>
    </parallax>
    <div class="main main-raised">
      <div class="section section-about-team pb-3">
        <div
          class="container"
          style="margin-top: 10px; height: 200px"
          v-if="items.length === 0 && loading"
        >
          <center>
            <md-progress-spinner
              md-mode="indeterminate"
              :md-diameter="150"
              :md-stroke="10"
            ></md-progress-spinner>
          </center>
        </div>
        <div
          class="container"
          style="margin-top: 10px; height: 200px"
          v-if="items.length === 0 && !loading"
        >
          <center>
            <div class="md-layout-item md-size-100 md-small-size-100">
              <h2
                style="margin-top: 0px; cursor: pointer"
                class="text-center"
                @click="openSurvey(item._id)"
              >
                No hay encuestas pendientes
              </h2>
            </div>
          </center>
        </div>
        <div class="container" v-if="items.length > 0">
          <div
            class="md-layout mt-2"
            v-for="(item, index) in items"
            :key="index"
          >
            <div class="md-layout-item md-size-100 md-small-size-100">
              <h2
                style="margin-top: 0px; cursor: pointer"
                class="text-center"
                @click="openSurvey(item._id)"
              >
                {{ item.nombre }}
              </h2>
              <h4 style="padding-top: 15px" class="description text-center">
                Período: {{ item.periodo.nombre }}, Fecha:
                {{ item.createdAt | fecha }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixins from "@/plugins/basicMixins";
import { mapGetters } from "vuex";
export default {
  mixins: [Mixins.HeaderImage],
  bodyClass: "about-us",
  data() {
    return {
      items: [],
      loading: true,
      // image: require("@/assets/img/bg9.jpg"),
      image: require("@/assets/img/blueboard.jpg"),
      unknownInstructor: require("@/assets/img/unknownUser.jpg"),
    };
  },
  computed: {
    ...mapGetters(["config"]),
    user() {
      return this.$store.getters.user;
    },
  },
  created() {
    if (this.items.length === 0) {
      this.axios
        .post(
          "/nom035/getMisEncuestasPendientes",
          {
            badge: parseInt(this.user.user),
            periodo: "6470d81189cff100277b37c1", // se necesita preguntar el periodo actual
          },
          this.config
        )
        .then((res) => {
          for (let i in res.data) {
            this.items.push(res.data[i]);
          }
          this.loading = false;
        })
        .catch((err) => {
          this.$store.dispatch("handlingServerErrors", err);
        });
    }
  },
  methods: {
    openSurvey(id) {
      this.$router.push({ name: "encuesta", params: { encuestaId: id } });
    },
    profilePic(user) {
      if (user.length > 0 && user[0].profilePic && user[0].profilePic !== "") {
        return `data:image/png;base64,${user[0].profilePic}`;
      } else {
        return this.unknownInstructor;
      }
    },
  },
  filters: {
    fecha(str) {
      if (!str || str === "") {
        return "";
      } else {
        let fecha = new Date(str);
        return fecha.toLocaleDateString("es-MX", {
          weekday: "long",
          day: "numeric",
          month: "long",
          year: "numeric",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mt-2 {
  margin-top: 1.5rem;
}
</style>
