<template>
  <div class="alert"
  :class="{ [`alert-${type}`]: type }">
    <div class="container">
      <div class="alert-icon">
        <md-icon>{{icon}}</md-icon>
      </div>
      <b v-if="title !== ''"> {{title}}: </b> {{text}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "info",
      validator(value) {
        return [
          "error",
          "danger",
          "success",
          "warning",
          "info",
          "dark"
        ].includes(value);
      }
    },
    title: { type: String, default: '' },
    text: { type: String, default: '' },
  },
  methods: {
  },
  computed: {
    icon () {
      switch (this.type) {
        case 'info':
          return 'info_outline'
        case 'success':
          return 'check'
        case 'warning':
          return 'warning'
        case 'danger':
        case 'error':
          return 'info_outline'
        default:
          return 'info'
      }
    }
  }
}
</script>